import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const UserDashboard = lazy(() => import('./dashboard/Dashboard'));
const Mycalendar = lazy(() => import('./my-calendar/Dashboard'));
const Usercalendar = lazy(() => import('./my-calendar/Dashboard'));
const Teamcalendar = lazy(() => import('./team-calendar/Dashboard'));
const Teams = lazy(() => import('./teams/Dashboard'));
const Month = lazy(() => import('./month/Dashboard'));
const Logs = lazy(() => import('./logs/Dashboard'));
const Activity = lazy(() => import('./activity/Dashboard'));
const Projects = lazy(() => import('./times/Projects'));
const Report = lazy(() => import('./times/Dashboard'));
const TimeReport = lazy(() => import('./time-report/Dashboard'));



const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));


const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));



const Mdi = lazy(() => import('./icons/Mdi'));


const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));

const BlankPage = lazy(() => import('./general-pages/BlankPage'));




class AppRoutes extends Component {

  constructor(props) {
    super(props);
  }

 
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/dashboard" component={ Dashboard } />
          <Route exact path="/user-dashboard/:id" component={ UserDashboard } />
          <Route exact path="/calendar" component={ Mycalendar } />
          <Route exact path="/user-calendar/:id" component={ Usercalendar } />
          <Route exact path="/team-calendar" render={(props) => <Teamcalendar notifications={this.props.notifications} preload={this.props.preload} {...props} />} />
          <Route exact path="/teams" component={ Teams } />
          <Route exact path="/month" component={ Month } />
          <Route exact path="/logs" component={ Logs } />
          <Route exact path="/activity" component={ Activity } />
          <Route exact path="/projects" component={ Projects } />
          <Route exact path="/reporting" component={ Report } />
          <Route exact path="/time-report" component={ TimeReport } />


          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/typography" component={ Typography } />


          <Route path="/form-Elements/basic-elements" component={ BasicElements } />

          <Route path="/tables/basic-table" component={ BasicTable } />


          <Route path="/icons/mdi" component={ Mdi } />


          <Route path="/charts/chart-js" component={ ChartJs } />


          <Route path="/user-pages/login-1" component={ Login } />

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />

          <Route path="/general-pages/blank-page" component={ BlankPage } />


          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
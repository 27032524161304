import axios from "axios";
// import { displaySystemNotification } from "services/systemNotifications";

const client = axios.create({
  baseURL: "/api/",
  /*headers: {
    "Content-Type": "application/json",
    "x-access-token": "token"
  }*/
});

export const request = (props) => {
  const options = props;

  const onSuccess = function (response) {
    return response.data;
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      const errorMessage = error.response.data;
      

      if (error.response.status == 404) {
        const hostname = window.location.origin;
        window.location.href = hostname;
      }

      if (error.response.status == 401) {
        const hostname = window.location.origin;
        const fullUrl = window.location.href;
        const loginUrl = hostname + "/user-pages/login-1";

        if (fullUrl != loginUrl) {
          window.location.href = loginUrl;
        }
      }

      // displaySystemNotification(errorMessage);
      // console.error("Status:", error.response.status);
      // console.error("Data:", error.response.data);
      // console.error("Headers:", error.response.headers);
    } else {
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
}

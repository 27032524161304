import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { request } from "../../services/request";
import moment from "moment";
import Badge from 'react-bootstrap/Badge';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      notifications: []
    }

    this.loadData = this.loadData.bind(this);
  }
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  logOut() {
    request({
      method: 'get',
      url: `auth/logout`
    }).then((resp) => {
      this.props.history.push("/dashboard");
    })
  }
  loadData() {
    this.setState({notifications: this.props.notifications});
  }
  componentDidMount(){
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.notifications !== prevProps.notifications) {
      this.loadData();
    }
  }
  render () {
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/"><img src={require('../../assets/images/logo.svg')} alt="logo" /></Link>
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>

          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" href="!#" onClick={this.logOut}>
                <i className="mdi mdi-power"></i>
              </a>
            </li>
            {this.state.notifications[0] ? (
              <li className="nav-item nav-settings d-none d-lg-block">
                <button type="button" className="nav-link border-0" onClick={this.toggleRightSidebar} >
                  <Badge pill bg="info" className="badge badge-pill badge-info">
                    {this.state.notifications.length}
                  </Badge>
                </button>
              </li>
            ) : null}
            
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
